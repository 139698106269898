<template>
    <div class="profile_edit_content_block">
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
        <div class="max-w-2xl bg-white rounded-md shadow">
            <form @submit.prevent="store">
                <div class="grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-4 mt-3 p-4">
                    <text-input v-model="form.title" :error="form.errors.title" class="sm:col-span-2" label="Name"
                        required="true" placeholder="i.e. " />
                    <text-input v-model="form.slug" :error="form.errors.slug" class="sm:col-span-2" label="Slug"
                        required="true" placeholder="i.e. " />
                    <text-input v-model="form.code" :error="form.errors.code" class="sm:col-span-2" label="Code"
                        required="true" placeholder="i.e. " />
                    <div class="sm:col-span-2 grid grid-cols-12">
                        <text-input v-model="form.icon" :error="form.errors.icon" class="col-span-11" label="Icon"
                            placeholder="i.e 1" disabled />
                        <vue3-icon-picker v-model="form.icon" class="mt-5" />
                    </div>
                    <div class="sm:col-span-2">
                        <Multiselect v-model="form.field_info" mode="tags" placeholder="Select Fields"
                            :options="fieldInfos" :limit="20" :search="true" />
                    </div>
                    <status-toogle-button v-model="form.status" :error="form.errors.status" label="Service Status"
                        class="sm:col-span-2 "></status-toogle-button>
                </div>
                <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
            </form>
        </div>
    </div>
</template>
<script>
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject, onMounted, watch } from 'vue'
import axios from 'axios';
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import TextareaInput from '@/Shared/TextareaInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Button from '@/Shared/Button'
import Vue3IconPicker from '@/Shared/components/Vue3IconPicker'
import Multiselect from '@vueform/multiselect'
export default {
    components: {
        Head,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        Breadcrumbs,
        StatusToogleButton,
        Button,
        TextareaInput,
        Vue3IconPicker,
        Multiselect
    },
    props: {
        permission: Array
    },
    layout: Layout,
    remember: 'form',
    setup(props) {
        const { permission } = toRefs(props)
        const form = useForm({
            title: null,
            slug: null,
            icon: 'fas fa-upload',
            code: null,
            field_info: null,
            status: null
        })
        const type = ref('create')
        const title = ref('Service')
        const url = ref('/backend/queue-services')
        const createUrl = ref('')
        const fieldInfos = ref([
            {
                value: 'name',
                label: 'Name'
            },
            {

                value: 'email',
                label: 'Email'
            },
            {
                value: 'mobile',
                label: 'Mobile'
            },
            {
                value: 'gender',
                label: 'Gender'
            },
            {
                value: 'service',
                label: 'Service'
            },
            {
                value: 'priority_lane',
                label: 'Priority lane'
            },
            {
                value: 'details',
                label: 'Details'
            }

        ])
        const swal = inject('$swal')
        const store = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.post(`${url.value}`, { forceFormData: true, })
                    swal.fire(
                        'Added!',
                        'Your infomation has been Added.',
                        'success'
                    )
                }
            });
        }
        const set_photo = (val) => {
            form.icon_img = val
        }
        return {
            form,
            type,
            title,
            url,
            createUrl,
            store,
            permission,
            set_photo,
            fieldInfos
        }
    }
}
</script>
