<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
        <form @submit.prevent="store">
            <div class="justify-between">
                <div class="g-white rounded-md shadow overflow-hidden">
                    <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                        <text-input v-model="form.name" :error="form.errors.name" class="pb-8 pr-6 w-full lg:w-1/2"
                            label="Name of Barangay Config" required="true" placeholder="i.e Interest"
                            @keyup="slugBuild" />
                        <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/2"
                            label="Slug" required="true" placeholder="i.e interest" />
                        <select-input v-model="form.service_info_id" :error="form.errors.service_info_id"
                            class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Service" required="true"
                            :action="load_field">
                            <option value=null>Select Service</option>
                            <option v-for="service in serviceInfo" :key="service.id" :value='service.id'>{{
                                service.service_name }} - {{ service.ser_name }}</option>
                        </select-input>
                        <template v-if="employeeData.length !== 0">
                            <select-input-function v-model="form.geo_region_id" :error="form.errors.geo_region_id"
                                class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Region" :action="load_province"
                                required="true">
                                <option value=''>Select Region</option>
                                <option v-for="region in regions" :key="region.id" :value='region.id'>{{
                                    region.region_name
                                    }}</option>
                            </select-input-function>
                            <select-input-function v-model="form.geo_provience_id" :error="form.errors.geo_provience_id"
                                class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Province" :action="load_municipality"
                                required="true">
                                <option value=''>Select Provience</option>
                                <option v-for="province in province_list" :key="province.id" :value='province.id'>{{
                                    province.provience_name }}</option>
                            </select-input-function>
                            <select-input-function v-model="form.geo_municipality_id"
                                :error="form.errors.geo_municipality_id" class="pb-8 pr-6 w-full lg:w-1/2"
                                label="Name of Municipality" required="true" :action="load_barangay">
                                <option value=''>Select Municipality</option>
                                <option v-for="municipal in municipality_list" :key="municipal.id"
                                    :value='municipal.id'>{{
                                        municipal.municipality_name }}</option>
                            </select-input-function>
                        </template>
                        <select-input v-model="form.geo_barangay_id" :error="form.errors.geo_barangay_id"
                            class="pb-8 pr-6 w-full lg:w-1/2" label="Name of Barangay" required="true">
                            <option value=''>Select Barangay</option>
                            <option v-for="barangay in barangay_list" :key="barangay.id" :value='barangay.id'>{{
                                barangay.barangay_name }}</option>
                        </select-input>
                        <text-input v-model="form.amount" :error="form.errors.amount" class="pb-8 pr-6 w-full lg:w-1/2"
                            label="Amount" required="true" placeholder="i.e 100" />
                        <textarea-input v-model="form.description" :error="form.errors.description"
                            class="pb-8 pr-6 w-full lg:w-1/2" label="Description" required="true"
                            placeholder="i.e Approved" />
                        <status-toogle-button v-model="form.status" :error="form.errors.status"
                            label="Barangay Config Status" class="pb-8 pr-6 w-full lg:w-1/2"></status-toogle-button>
                    </div>
                </div>
                <div class="flex flex-col g-white overflow-hidden rounded-md shadow">
                    <div class="border-b-2 caption_header_block flex gap-2 items-center p-4 px-4">
                        <i class="fa fa-cogs"></i>
                        <span>Others</span>
                        <Button :name="'Add'" :icon="'far fa-plus-circle'" :method="open_detail_popup"></Button>
                    </div>
                    <div class="sm:col-span-3 gap-y-2 gap-x-1 mb-1">
                        <div class="gap-2 grid grid-cols-2 m-2">
                            <div class="bg-white border-2 max-w-lg p-2 relative rounded-lg shadow-md w-full"
                                v-for="(detail, index) in form.details" :key="index">
                                <i class="fa-light fa-xmark absulate absolute -right-1.5 -top-1.5 flex h-5 w-5 cursor-pointer items-center justify-center rounded-full border border-gray-300 bg-white text-red-700 shadow-[0_0_5px_#999] p-1"
                                    @click="removeDetails(index)"></i>
                                <!-- Top Header -->
                                <div class="p2 mb-2 flex justify-between border-b">
                                    <div class=" " v-if="variable_list.length > 0">
                                        <h5 class="text-xl font-bold text-gray-800">{{
                                            variable_title(detail.variable_id) }}
                                        </h5>
                                    </div>
                                    <!-- Amount Section -->
                                    <div class="flex items-center">
                                        <p class="font-bold text-green-600" v-if="detail.amount">{{ detail.amount
                                            }}<template v-if="detail.type == 'percentage'">%</template></p>
                                        <p class="font-bold text-green-600" v-else>{{ detail.recurring_amount
                                            }}<template v-if="detail.type == 'percentage'">%</template>
                                        </p>
                                    </div>
                                </div>
                                <!-- Card Body (Other Information) -->
                                <div class="space-y-4">
                                    <!-- Recurring -->
                                    <div class="flex items-center gap-2" v-if="detail.recurring">
                                        <span class="text-gray-600">Recurring : </span>
                                        <span class="text-gray-900">{{ detail.recurring }}</span>
                                    </div>
                                    <!-- Recurring Type -->
                                    <div class="flex items-center gap-2"
                                        v-if="detail.recurring && detail.recurring_type">
                                        <span class="text-gray-600">Recurring Type:</span>
                                        <span class="text-gray-900">{{ detail.recurring_type }}</span>
                                    </div>
                                    <!-- Number of Recurring -->
                                    <div class="flex items-center gap-2"
                                        v-if="detail.recurring && detail.recurring_type">
                                        <span class="text-gray-600">Number of Recurring:</span>
                                        <span class="text-gray-900">{{ detail.recurring_amount }}</span>
                                    </div>
                                    <!-- Recurring Amount -->
                                </div>
                            </div>
                        </div>
                        <div class="col-span-2">
                            <detail :details="form.details" :detailopen="detailopen"
                                @UpdateDetailPopup="update_detail_popup" @UpdateDetail="update_detail">
                            </detail>
                        </div>
                        <div v-if="form.errors.details" class="form-error">Details required.
                        </div>
                    </div>
                </div>
                <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject, onMounted, watch } from 'vue'
import Layout from '@/Shared/Layout'
import TextareaInput from '@/Shared/TextareaInput'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Button from '@/Shared/Button'
import Detail from './components/Details'
import SelectInputFunction from '@/Shared/SelectInputFunction'
export default {
    components: {
        Head,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        Breadcrumbs,
        StatusToogleButton,
        Button,
        TextareaInput,
        Detail,
        SelectInputFunction
    },
    layout: Layout,
    props: {
        serviceInfo: Array,
        regions: Array,
        employee: Object
    },
    remember: 'form',
    setup(props) {
        const form = useForm({
            name: null,
            slug: null,
            description: null,
            amount: null,
            status: true,
            service_info_id: null,
            geo_region_id: '',
            geo_provience_id: '',
            geo_municipality_id: '',
            geo_barangay_id: '',
            details: []
        })
        const { serviceInfo, regions, employee } = toRefs(props)
        const type = ref('create')
        const title = ref('Barangay Config')
        const url = ref('/backend/payment-config-barangays')
        const createUrl = ref('')
        const swal = inject('$swal')
        const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
        const detailopen = ref(false)
        const employeeData = ref({})
        const store = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.post(`${url.value}`, { forceFormData: true, })
                    swal.fire(
                        'Added!',
                        'Your infomation has been Added.',
                        'success'
                    )
                }
            });

        }
        const update_detail_popup = (val) => {
            detailopen.value = val
        }
        const open_detail_popup = () => {
            detailopen.value = true
        }
        const update_detail = (val) => {
            let obj = form.details.find(o => o.variable_id === val.variable_id);
            if (obj === undefined) {
                form.details.push(val)
            } else {
                alert('You have already add that type');
            }
        }
        const type_list = ref([
            {
                id: 'fixed',
                title: 'Fixed'
            },
            {
                id: 'percentage',
                title: 'Percentage'
            }
        ])
        const recurring_list = ref([
            {
                id: 'yes',
                title: 'Yes'
            },
            {
                id: 'no',
                title: 'No'
            }
        ])
        const recurring_type_list = ref([
            {
                id: 'day',
                title: 'Day'
            },
            {
                id: 'week',
                title: 'Week'
            },
            {
                id: 'month',
                title: 'Month'
            },
            {
                id: 'year',
                title: 'Year'
            }
        ])
        const variable_list = ref([])
        const load_type = async () => {
            await axios.post(`/${backendPrefix.value}/payment-variables/activity-variables`)
                .then(function (response) {
                    ////console.log(response);
                    variable_list.value = response.data
                }.bind(this));
        }


        const variable_title = (value) => {
            const index = variable_list.value.findIndex(p => p.id == value);
            console.log(index);
            return variable_list.value[index].title;
        }
        const type_title = (value) => {
            const index = type_list.value.findIndex(p => p.id == value);
            return type_list.value[index].title;

        }
        const recurring_title = (value) => {
            const index = recurring_list.value.findIndex(p => p.id == value);
            return recurring_list.value[index].title;

        }
        const recurring_type_title = (value) => {
            const index = recurring_type_list.value.findIndex(p => p.id == value);
            return recurring_type_list.value[index].title;

        }
        const slugBuild = () => {
            form.slug = form.name.replace(/\s/g, '-').toLowerCase()
        }
        const setdata = async () => {
            form.geo_region_id = employee.value.region_id
            form.geo_provience_id = employee.value.province_id
            form.geo_municipality_id = employee.value.municipality_id
            form.geo_region_id = employee.value.region_id
            if (form.geo_municipality_id) {
                await load_barangay();
            }
        }
        onMounted(() => {
            load_type();
            setdata();
        })

        const removeDetails = (index) => {
            if (index !== -1) {
                form.details.splice(index, 1)
            }
        }
        const province_list = ref([])
        const load_province = async () => {

            province_list.value = []
            let data = {
                geo_region_id: form.geo_region_id
            }
            await axios.post('../provinces/activeprovinces', data)
                .then(function (response) {
                    province_list.value = response.data
                    form.geo_provience_id = ''
                    form.geo_municipality_id = ''
                    form.geo_barangay_id = ''
                    if (form.geo_provience_id) load_municipality(1)
                }.bind(this));

        }

        const municipality_list = ref([])
        const load_municipality = async () => {
            municipality_list.value = []
            let data = {
                geo_provience_id: form.geo_provience_id
            }
            await axios.post('../municipalities/activemunicipalities', data)
                .then(function (response) {
                    form.geo_municipality_id = ''
                    form.geo_barangay_id = ''
                    municipality_list.value = response.data
                    if (form.geo_municipality_id) load_barangay(1)
                }.bind(this));
        }

        const barangay_list = ref([])
        const load_barangay = async () => {
            barangay_list.value = []
            let data = {
                geo_municipality_id: form.geo_municipality_id
            }
            await axios.post('../barangays/activebarangays', data)
                .then(function (response) {
                    form.geo_barangay_id = ''
                    barangay_list.value = response.data
                }.bind(this));
        }

        return {
            form,
            type,
            title,
            url,
            createUrl,
            store,
            serviceInfo,
            type_list,
            recurring_list,
            recurring_type_list,
            load_type,
            variable_list,
            variable_title,
            type_title,
            recurring_title,
            recurring_type_title,
            detailopen, open_detail_popup, update_detail_popup, update_detail,
            backendPrefix,
            slugBuild,
            removeDetails,
            regions,
            province_list,
            load_province,
            municipality_list,
            load_municipality,
            barangay_list,
            load_barangay,
            employee,
            employeeData,
            setdata
        }
    }
}
</script>
