<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
        <trashed-message v-if="decisionstep.deleted_at" class="mb-6" @restore="restore"> This Religion has been deleted.
        </trashed-message>
        <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
            <form @submit.prevent="update">
                <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                    <text-input v-model="form.decision_title" :error="form.errors.decision_title"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Title" required="true" placeholder="i.e Approved" />
                    <text-input v-model="form.decision_title_citizen" :error="form.errors.decision_title_citizen"
                        class="pb-8 pr-6 w-full lg:w-1/2" label="Citizen Title" required="true"
                        placeholder="i.e Request Approved" />
                    <status-toogle-button v-model="form.has_notification" :error="form.errors.has_notification"
                        label="Has Notification" class="pb-8 pr-6 w-full lg:w-1/1"></status-toogle-button>
                    <text-input v-if="form.has_notification == true" v-model="form.sms" :error="form.errors.sms"
                        class="pb-8 pr-6 w-full lg:w-1/1" label="SMS" required="true"
                        placeholder="i.e Your request has been approved" />
                    <text-input v-if="form.has_notification == true" v-model="form.email" :error="form.errors.email"
                        class="pb-8 pr-6 w-full lg:w-1/1" label="Email" required="true"
                        placeholder="i.e Your request has been approved" />
                    <select-input v-model="form.type" :error="form.errors.d_type" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Decision Type" required="true">
                        <option value=null>Select Decision Type</option>
                        <option value="1">Forward</option>
                        <option value="2">Return</option>
                        <option value="3">Reject</option>
                        <option value="4">Complete</option>
                        <option value="5">Resubmit</option>
                        <option value="6">Print</option>
                        <option value="7">Payment Request</option>
                        <option value="8">Payment Success</option>
                        <option value="9">Payment Resubmit</option>
                        <option value="10">Received</option>
                        <option value="11">In-Progress</option>
                        <option value="12">Generate Certificate</option>
                        <option value="13">Cancel</option>
                        <option value="15">Document Request</option>
                        <option value="16">Document Received</option>
                        <option value="17">Document Success</option>
                        <option value="18">Document Resubmit</option>
                        <option value="21">Reappointment</option>
                        <option value="22">Authorized</option>
                    </select-input>
                    <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status of Decision"
                        class="pb-8 mt-6 pr-6 w-full lg:w-1/2"></status-toogle-button>
                    <status-toogle-button v-model="form.is_resolve" :error="form.errors.is_resolve" label="Is Resolve"
                        class="pb-8 pr-6 w-full lg:w-1/2"></status-toogle-button>
                    <status-toogle-button v-model="form.is_open" :error="form.errors.is_open" label="Is Open"
                        class="pb-8 pr-6 w-full lg:w-1/2"></status-toogle-button>
                    <status-toogle-button v-model="form.permanent_reject" :error="form.errors.permanent_reject"
                        label="Is Permanent Reject" class="pb-8 pr-6 w-full lg:w-1/2"></status-toogle-button>
                </div>
                <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
                    <button v-if="!decisionstep.deleted_at && permission.edit > 0" class="text-red-600 hover:underline"
                        tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
                    <Button v-if="permission.edit > 0" :type="type" :name="head" :url="createUrl"
                        :loading="form.processing"></Button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import { ref, toRefs, inject } from 'vue'
    import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
    import Icon from '@/Shared/Icon'
    import Layout from '@/Shared/Layout'
    import TextInput from '@/Shared/TextInput'
    import SelectInput from '@/Shared/SelectInput'
    import LoadingButton from '@/Shared/LoadingButton'
    import TrashedMessage from '@/Shared/TrashedMessage'
    import StatusToogleButton from '@/Shared/StatusToogleButton'
    import Breadcrumbs from '@/Shared/Breadcrumbs'
    import DeleteIcon from '@/Shared/DeleteIcon'
    import Button from '@/Shared/Button'
    export default {
        components: {
            Head,
            Icon,
            Link,
            LoadingButton,
            SelectInput,
            TextInput,
            TrashedMessage,
            StatusToogleButton,
            Breadcrumbs,
            DeleteIcon,
            Button
        },
        layout: Layout,
        props: {
            decisionstep: Object,
            permission: Object,
        },
        remember: 'form',
        setup(props) {
            const { decisionstep, permission } = toRefs(props)
            const type = ref('edit')
            const title = ref(decisionstep.value.decision_title)
            const head = ref('Decision')
            const url = ref('/backend/decisionsteps')
            const createUrl = ref('')
            const elm = ref(this)
            const swal = inject('$swal')
            const form = useForm({
                decision_title: decisionstep.value.decision_title,
                decision_title_citizen: decisionstep.value.decision_title_citizen,
                sms: decisionstep.value.sms,
                email: decisionstep.value.email,
                type: decisionstep.value.type,
                has_notification: decisionstep.value.has_notification,
                status: decisionstep.value.status,
                is_resolve: decisionstep.value.is_resolve,
                is_open: decisionstep.value.is_open,
                permanent_reject: decisionstep.value.permanent_reject,
            })
            const update = () => {
                swal({
                    title: 'Are you sure?',
                    text: "You want to proceed with this action",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'Cancel',
                }).then((result) => {
                    if (result.isConfirmed) {
                        form.put(`${url.value}/${decisionstep.value.id}`)
                        title.value = form.decision_title
                        swal.fire(
                            'Updated!',
                            'Your infomation has been Updated.',
                            'success'
                        )
                    }
                });
            }
            const destroy = () => {
                swal({
                    title: 'Are you sure?',
                    text: "Do you really want to delete this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        form.delete(`${url.value}/${decisionstep.value.id}`)
                        swal.fire(
                            'Deleted!',
                            'Your infomation has been deleted.',
                            'success'
                        )
                    }
                });
            }
            const restore = () => {
                swal({
                    title: 'Are you sure?',
                    text: "You want to restore this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Resore it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        form.put(`${url.value}/${decisionstep.value.id}/restore`)
                        swal.fire(
                            'Restored!',
                            'Your infomation has been Restored.',
                            'success'
                        )
                    }
                });
            }
            const statusCheck = () => {
                if (decisionstep.value.status == 1) {
                    form.status = true;
                    return true;
                } else {
                    form.status = false;
                    return false;
                }
            }
            return {
                form,
                decisionstep,
                type,
                title,
                head,
                url,
                createUrl,
                update,
                destroy,
                restore,
                statusCheck,
                permission
            }
        },
        created: function () {
            this.statusCheck();
        }
    }
</script>
