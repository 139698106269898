<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
        <trashed-message v-if="notificationSetting.deleted_at" class="mb-6" @restore="restore"> This Faq has been
            deleted.
        </trashed-message>
        <div class="max-w-2xl bg-white rounded-md shadow overflow-hidden">
            <form @submit.prevent="update">
                <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                    <text-input v-model="form.title" :error="form.errors.title" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Title" required="true" placeholder="i.e Registration SMS" />
                    <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Slug" required="true" placeholder="i.e registration-sms" />
                    <select-input v-model="form.type" :error="form.errors.type" class="pb-8 pr-6 w-full lg:w-1/2"
                        label="Type" required="true">
                        <option :value=null>Select Type</option>
                        <option value='sms'>SMS</option>
                        <option value='email'>Email</option>
                        <option value='push'>Push</option>
                    </select-input>
                    <div class="w-full mb-3">
                        <div class="flex justify-between ">
                            <h6 class="text-lg font-semibold mb-4">Phone Numbers </h6>
                            <button class="flex items-center gap-1 ml-auto text-[11px] font-semibold" type="button"
                                @click="addNumber()">
                                <i class="far fa-plus-circle"></i> Add
                            </button>
                        </div>
                        <div v-for="(item, index) in form.details" :key="item.id" class="flex items-center mb-1">
                            <text-input v-model="item.number" class="flex-1" label="Phone Number" required="true"
                                placeholder="i.e 9123456789" />
                            <status-toogle-button v-model="item.status" class="w-24 mt-4"></status-toogle-button>
                            <i class="fa-duotone fa-trash right-0 text-red-700 cursor-pointer mt-4" role="button"
                                @click="removeNumber(index)"></i>
                        </div>
                    </div>
                    <status-toogle-button v-model="form.status" :error="form.errors.status" label="Status"
                        class="pb-8 pr-6 w-full lg:w-1/2"></status-toogle-button>
                </div>
                <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
                    <button v-if="!notificationSetting.deleted_at && permission.edit > 0"
                        class="text-red-600 hover:underline" tabindex="-1" type="button"
                        @click="destroy"><delete-icon></delete-icon></button>
                    <Button v-if="permission.edit > 0" :type="type" :name="head" :url="createUrl"
                        :loading="form.processing"></Button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from '@/Shared/Breadcrumbs'
import Button from '@/Shared/Button'
import DeleteIcon from '@/Shared/DeleteIcon'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import TextareaInput from '@/Shared/TextareaInput'
import TextInput from '@/Shared/TextInput'
import TrashedMessage from '@/Shared/TrashedMessage'
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { ref, toRefs, inject } from 'vue'
export default {
    components: {
        Head,
        Icon,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        TrashedMessage,
        StatusToogleButton,
        Breadcrumbs,
        DeleteIcon,
        Button,
        TextareaInput
    },
    layout: Layout,
    props: {
        notificationSetting: Object,
        permission: Object,
    },
    remember: 'form',
    setup(props) {
        const { notificationSetting, permission } = toRefs(props)
        const type = ref('edit')
        const title = ref(notificationSetting.value.title)
        const head = ref('Notification Setting')
        const url = ref('/backend/notification-settings')
        const swal = inject('$swal')
        const createUrl = ref('')
        const elm = ref(this)
        const form = useForm({
            title: notificationSetting.value.title,
            slug: notificationSetting.value.slug,
            status: notificationSetting.value.status,
            type: notificationSetting.value.type,
            details: notificationSetting.value.details?.map((detail) => ({ id: detail.id, number: detail.number, status: detail.status }))
        })
        const update = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to proceed with this action",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    form.put(`${url.value}/${notificationSetting.value.id}`)
                    title.value = form.title
                    swal.fire(
                        'Updated!',
                        'Your infomation has been Updated.',
                        'success'
                    )
                }
            });
        }

        const destroy = () => {
            swal({
                title: 'Are you sure?',
                text: "Do you really want to delete this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.delete(`${url.value}/${notificationSetting.value.id}`)
                    swal.fire(
                        'Deleted!',
                        'Your infomation has been deleted.',
                        'success'
                    )
                }
            });
        }
        const restore = () => {
            swal({
                title: 'Are you sure?',
                text: "You want to restore this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Resore it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    form.put(`${url.value}/${notificationSetting.value.id}/restore`)
                    swal.fire(
                        'Restored!',
                        'Your infomation has been Restored.',
                        'success'
                    )
                }
            });
        }
        // Function to add a new phone number
        const addNumber = () => {
            form.details.push({ id: form.details.length + 1, number: '', status: true });
        };

        // Function to remove a phone number
        const removeNumber = (index) => {
            form.details.splice(index, 1);
        };
        return {
            form,
            notificationSetting,
            type,
            title,
            head,
            url,
            createUrl,
            update,
            destroy,
            restore,
            swal,
            permission,
            addNumber,
            removeNumber
        }
    }

}
</script>
