<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url"></Breadcrumbs>
        <div class="w-full bg-white rounded-md shadow overflow-hidden">
            <form @submit.prevent="store">
                <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                    <fieldset class="border border-solid border-gray-300 p-3 w-full lg:w-1/2">
                        <legend class="text-sm">Citizen</legend>
                        <div>
                            <text-input v-model="form.title_citizen" :error="form.errors.title_citizen"
                                class="pb-8 pr-6 w-full lg:w-1/1" label="Title" required="true"
                                placeholder="i.e Approved" />
                            <textarea-input v-model="form.description_citizen" :error="form.errors.description_citizen"
                                class="pb-8 pr-6 w-full lg:w-1/1" label="Description" required="true"
                                placeholder="i.e Approved" />
                            <text-input v-model="form.color_citizen" :error="form.errors.color_citizen"
                                class="pb-8 pr-6 w-full lg:w-1/1" label="Color" required="true" type="color" />
                            <div class="pb-8 pr-6 w-full lg:w-1/1">
                                <Multiselect v-model="form.citizen_decision_steps" mode="tags" placeholder="Decisions"
                                    :options="desionStep" :limit="20" :search="true" />
                            </div>
                        </div>
                    </fieldset>
                    <fieldset class="border border-solid border-gray-300 p-3 w-full lg:w-1/2">
                        <legend class="text-sm">Workflow</legend>
                        <div>
                            <text-input v-model="form.title_workflow" :error="form.errors.title_workflow"
                                class="pb-8 pr-6 w-full lg:w-1/1" label="Title" required="true"
                                placeholder="i.e Approved" />
                            <textarea-input v-model="form.description_workflow"
                                :error="form.errors.description_workflow" class="pb-8 pr-6 w-full lg:w-1/1"
                                label="Description" required="true" placeholder="i.e Approved" />
                            <text-input v-model="form.color_workflow" :error="form.errors.color_workflow"
                                class="pb-8 pr-6 w-full lg:w-1/1" label="Color" required="true" type="color" />
                            <div class="pb-8 pr-6 w-full lg:w-1/1">
                                <Multiselect v-model="form.workflow_decision_steps" mode="tags" placeholder="Decisions"
                                    :options="desionStep" :limit="20" :search="true" />
                            </div>
                        </div>
                    </fieldset>
                    <status-toogle-button v-model="form.status" :error="form.errors.status"
                        label="Status of Decision Step" class="mt-10 pb-8 pr-6 w-full lg:w-1/1"></status-toogle-button>
                </div>
                <Button :type="type" :name="title" :url="createUrl" :loading="form.processing"></Button>
            </form>
        </div>
    </div>
</template>

<script>
import { Head, Link, useForm } from "@inertiajs/inertia-vue3";
import { ref, toRefs, onMounted, inject } from "vue";
import axios from "axios";
import Layout from "@/Shared/Layout";
import TextInput from "@/Shared/TextInput";
import TextareaInput from "@/Shared/TextareaInput";
import SelectInput from "@/Shared/SelectInput";
import LoadingButton from "@/Shared/LoadingButton";
import Breadcrumbs from "@/Shared/Breadcrumbs";
import StatusToogleButton from "@/Shared/StatusToogleButton";
import Button from "@/Shared/Button";
import Multiselect from "@vueform/multiselect";
export default {
    components: {
        Head,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        TextareaInput,
        Breadcrumbs,
        StatusToogleButton,
        Button,
        Multiselect,
    },
    layout: Layout,
    remember: "form",
    setup() {
        const form = useForm({
            title_citizen: null,
            description_citizen: null,
            color_citizen: null,
            citizen_decision_steps: [],
            title_workflow: null,
            description_workflow: null,
            color_workflow: null,
            workflow_decision_steps: [],
            status: true,
        });

        const type = ref("create");
        const title = ref("Application Status");
        const url = ref("/backend/citizen-application-statuses");
        const createUrl = ref("");
        const desionStep = ref([]);
        const swal = inject("$swal");
        const store = () => {
            swal({
                title: "Are you sure?",
                text: "You want to proceed with this action",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm",
                cancelButtonText: "Cancel",
            }).then((result) => {
                if (result.isConfirmed) {
                    form.post(`${url.value}`, { forceFormData: true });
                    swal.fire("Added!", "Your infomation has been Added.", "success");
                }
            });
        };

        const loadStep = async () => {
            let data = {};
            await axios.post("../decisionsteps/allsteps", data).then(
                function (response) {
                    //console.log(response.data);
                    response.data.forEach((element) => {
                        let obj = {
                            value: element.id,
                            label: element.decision_title,
                        };
                        desionStep.value.push(obj);
                    });
                    //desionStep.value = response.data;
                }.bind(this)
            );
        };
        onMounted(() => {
            loadStep();
        });
        return {
            form,
            type,
            title,
            url,
            createUrl,
            desionStep,
            store,
            loadStep,
        };
    },
};
</script>
<style scoped>
.multiselect__content-wrapper

/*, .multiselect__element */
    {
    width: fit-content;
}
</style>
