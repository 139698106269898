<template>
    <div class="properties_block">
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Type</label>
            <div>Number Input</div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Name</label>
            <div><input type="text" class="px-2" v-model="property_form_data.name" placeholder="Enter Name"
                    @keyup="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Label</label>
            <div><input type="text" class="px-2" v-model="property_form_data.label" placeholder="Enter Label"
                    @keyup="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Placeholder</label>
            <div><input type="text" class="px-2" v-model="property_form_data.placeholder"
                    placeholder="Enter Placeholder" @keyup="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Maxlength</label>
            <div><input type="number" class="pl-2" v-model="property_form_data.maxlength" placeholder="e.g. 30" min="1"
                    @keyup="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Min Number</label>
            <div><input type="number" class="pl-2" v-model="property_form_data.min_number" placeholder="e.g. 30" min="0"
                    @keyup="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Max Number</label>
            <div><input type="number" class="pl-2" v-model="property_form_data.max_number" placeholder="e.g. 30" min="0"
                    @keyup="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Per Step</label>
            <div><input type="number" class="pl-2" v-model="property_form_data.step" placeholder="e.g. 0.1" min="0"
                    step="0.1" @keyup="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Default Value</label>
            <div><input type="number" class="pl-2" v-model="property_form_data.default_val" placeholder="e.g. 0.1"
                    min="0" step="0.1" @keyup="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Prefix</label>
            <div><input type="text" class="px-2" v-model="property_form_data.prefix" placeholder="Enter prefix"
                    @keyup="add_properties" @change="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Suffix</label>
            <div><input type="text" class="px-2" v-model="property_form_data.suffix" placeholder="Enter suffix"
                    @keyup="add_properties" @change="add_properties" /></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Ouput Alpha</label>
            <div>
                <div v-if="property_form_data.output_alpha" class="flex flex-row gap-1 cursor-pointer"
                    @click="alpha_update(false)">
                    <i class="far fa-check-circle"></i>
                    <span>No</span>
                </div>
                <div v-else class="flex flex-row gap-1 cursor-pointer" @click="alpha_update(true)">
                    <i class="far fa-circle"></i>
                    <span>Yes</span>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Display To Words</label>
            <div class="form-check form-switch">
                <input
                    class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn"
                    type="checkbox" role="switch" id="flexSwitchCheckChecked"
                    v-model="property_form_data.display_to_words" @change="add_properties" />
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Required</label>
            <div class="form-check form-switch">
                <input
                    class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn"
                    type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.required"
                    @change="add_properties" />
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>For Certificate <sup v-if="certificate_data[property_form_data.name]">*</sup></label>
            <div class="form-check form-switch">
                <input
                    class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn"
                    type="checkbox" role="switch" id="flexSwitchCheckChecked"
                    v-model="property_form_data.for_certificate" @change="add_properties" />
            </div>
        </div>
        <div v-if="qr_data" class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>For QR Data <sup v-if="qr_data[property_form_data.name]">*</sup></label>
            <div class="form-check form-switch">
                <input
                    class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn"
                    type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.for_qr"
                    @change="add_properties" />
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>For Readonly</label>
            <div class="form-check form-switch">
                <input
                    class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn"
                    type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.for_readonly"
                    @change="add_properties" />
            </div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Check Valid Type</label>
            <div><select v-model="property_form_data.check_valid_type" @change="add_properties">
                    <option value="">Choose One</option>
                    <option v-for="(field_type, field_value) in field_validation_types" :value="field_value">{{
                        field_type }}</option>
                </select></div>
        </div>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Same as</label>
            <div class="form-check form-switch">
                <input
                    class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn"
                    type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.same_as"
                    @change="add_properties" />
            </div>
        </div>
        <template v-if="property_form_data.same_as">
            <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                <label>Same as Label</label>
                <div><input type="text" class="px-2" v-model="property_form_data.same_as_label"
                        placeholder="Enter Label" @keyup="add_properties" @change="add_properties" /></div>
            </div>
            <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                <label>Same as Input Field</label>
                <div><input type="text" class="px-2" v-model="property_form_data.same_as_col_name"
                        placeholder="Enter Input Field" @keyup="add_properties" @change="add_properties" /></div>
            </div>
        </template>
        <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
            <label>Project Remaining Value</label>
            <div class="form-check form-switch">
                <input
                    class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn"
                    type="checkbox" role="switch" id="flexSwitchCheckChecked"
                    v-model="property_form_data.project_remaining_value" @change="add_properties" />
            </div>
        </div>
        <div class="grid items-center border border-collapse gap-2 p-2">
            <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                <label>Has Relation</label>
                <div class="form-check form-switch">
                    <input
                        class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn"
                        type="checkbox" role="switch" id="flexSwitchCheckChecked"
                        v-model="property_form_data.has_relation" @change="add_properties" />
                </div>
            </div>

            <div v-if="property_form_data.has_relation == true"
                class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                <label>Relation Field Name</label>
                <div><input type="text" class="px-2" v-model="property_form_data.relation_col_name"
                        @keyup="add_properties" @change="add_properties" /></div>
            </div>
        </div>
        <div class="grid items-center border border-collapse gap-2 p-2">
            <div class="grid grid-cols-2 items-center border border-collapse gap-2 p-2">
                <label>Dependent</label>
                <div class="form-check form-switch">
                    <input
                        class="form-check-input appearance-none float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm switch_btn"
                        type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="property_form_data.dependent"
                        @change="add_properties" />
                </div>
            </div>
            <template v-if="property_form_data.dependent">
                <table class="table border border-collapse border-gray-300 bg-gray-50 text-[10px]">
                    <tr>
                        <th class="bg-gray-100 border border-gray-300 p-1">Col</th>
                        <th class="bg-gray-100 border border-gray-300 p-1">Opt</th>
                        <th class="bg-gray-100 border border-gray-300 p-1 text-center">#</th>
                    </tr>
                    <template v-if="property_form_data.col_dependencies.length > 0">
                        <tr v-for="(layer_dependency, index) in property_form_data.col_dependencies">
                            <td class="border border-gray-300 p-1">
                                <div><input class="px-1 border rounded-md w-14 h-6" type="text"
                                        v-model="layer_dependency.col_name" placeholder="i.e. " /></div>
                            </td>
                            <td class="border border-gray-300 p-1">
                                <div><select class="px-1 border rounded-md w-11 h-6" type="text"
                                        v-model="layer_dependency.opt">
                                        <option v-for="(opt_val, opt_index) in logical_operation_list"
                                            :value="opt_index">{{ opt_val }}</option>
                                    </select></div>
                            </td>

                            <td class="text-center">
                                <i class="fa-solid fa-trash cursor-pointer text-red-600 z-10"
                                    @click="remove_layer_dependency(index, assign_index)"></i>
                            </td>
                        </tr>
                    </template>
                    <tr v-else>
                        <td colspan="3" class="border border-gray-300 p-2 text-center"><em>Empty</em></td>
                    </tr>
                    <tr>
                        <td colspan="3" class="border border-gray-300 p-2 text-center">
                            <div class="flex flex-row items-center justify-center gap-2 border border-collapse bg-gray-100 cursor-pointer rounded-md h-6"
                                @click="add_new_dependency(assign_index)">
                                <i class="fa fa-plus"></i>
                                <span>Add New</span>
                            </div>
                        </td>
                    </tr>
                </table>
            </template>
        </div>
        <!-- <pre>{{ data }}</pre> -->
    </div>
</template>
<script setup>
import { ref, watch } from 'vue'
const props = defineProps({
    data: Object,
    certificate_data: Object,
    qr_data: Object,
    field_validation_types: Object
})
const emit = defineEmits(['addProperties', 'modifyCertificateData', 'removeCertificateData'])
const name_key_press = ref(false)
const property_form_data = ref({
    name: props.data && props.data.name ? props.data.name : '',
    type: props.data && props.data.type ? props.data.type : 'number_input',
    value: props.data && props.data.value ? props.data.value : '',
    label: props.data && props.data.label ? props.data.label : '',
    placeholder: props.data && props.data.placeholder ? props.data.placeholder : '',
    maxlength: props.data && props.data.maxlength ? props.data.maxlength : '',
    min_number: props.data && props.data.min_number ? props.data.min_number : '0',
    max_number: props.data && props.data.max_number ? props.data.max_number : '0',
    step: props.data && props.data.step ? props.data.step : '0.1',
    default_val: props.data && props.data.default_val !== '' ? props.data.default_val : '',
    prefix: props.data && props.data.prefix ? props.data.prefix : '',
    suffix: props.data && props.data.suffix ? props.data.suffix : '',
    output_alpha: props.data && props.data.output_alpha ? props.data.output_alpha : false,
    display_to_words: props.data && props.data.display_to_words ? props.data.display_to_words : false,
    required: props.data && props.data.required ? props.data.required : false,
    for_certificate: props.data && props.data.for_certificate ? props.data.for_certificate : false,
    for_qr: props.data && props.data.for_qr ? props.data.for_qr : false,
    for_readonly: props.data && props.data.for_readonly ? props.data.for_readonly : false,
    check_valid_type: props.data && props.data.check_valid_type ? props.data.check_valid_type : '',
    same_as: props.data && props.data.same_as ? props.data.same_as : false,
    same_as_checked: props.data && props.data.same_as_checked ? props.data.same_as_checked : false,
    same_as_label: props.data && props.data.same_as_label ? props.data.same_as_label : '',
    same_as_col_name: props.data && props.data.same_as_col_name ? props.data.same_as_col_name : '',
    project_remaining_value: props.data && props.data.project_remaining_value ? props.data.project_remaining_value : false,
    dependent: props.data && props.data.dependent ? props.data.dependent : false,
    has_relation: props.data && props.data.has_relation ? props.data.has_relation : false,
    relation_col_name: props.data && props.data.relation_col_name ? props.data.relation_col_name : '',
    col_dependencies: props.data && props.data.col_dependencies ? props.data.col_dependencies : []
})
const logical_operation_list = ref({
    '=': '=',
    '>': '>',
    '>=': '>=',
    '<': '<',
    '<=': '<=',
    '!=': '!='
})
watch(() => props.data, (updateData, oldValue) => {
    // //console.log(updateData, oldValue)
    name_key_press.value = false
    property_form_data.value = {
        name: updateData && updateData.name ? updateData.name : '',
        type: updateData && updateData.type ? updateData.type : 'number_input',
        value: updateData && updateData.value ? updateData.value : '',
        label: updateData && updateData.label ? updateData.label : '',
        placeholder: updateData && updateData.placeholder ? updateData.placeholder : '',
        maxlength: updateData && updateData.maxlength ? updateData.maxlength : '',
        min_number: updateData && updateData.min_number ? updateData.min_number : '0',
        max_number: updateData && updateData.max_number ? updateData.max_number : '0',
        step: updateData && updateData.step ? updateData.step : '0.1',
        default_val: updateData && updateData.default_val !== '' ? updateData.default_val : '',
        prefix: updateData && updateData.prefix ? updateData.prefix : '',
        suffix: updateData && updateData.suffix ? updateData.suffix : '',
        output_alpha: updateData && updateData.output_alpha ? updateData.output_alpha : false,
        display_to_words: updateData && updateData.display_to_words ? updateData.display_to_words : false,
        required: updateData && updateData.required ? updateData.required : false,
        for_certificate: updateData && updateData.for_certificate ? updateData.for_certificate : false,
        for_qr: updateData && updateData.for_qr ? updateData.for_qr : false,
        for_readonly: updateData && updateData.for_readonly ? updateData.for_readonly : false,
        check_valid_type: updateData && updateData.check_valid_type ? updateData.check_valid_type : '',
        same_as: updateData && updateData.same_as ? updateData.same_as : false,
        same_as_checked: updateData && updateData.same_as_checked ? updateData.same_as_checked : false,
        same_as_label: updateData && updateData.same_as_label ? updateData.same_as_label : '',
        same_as_col_name: updateData && updateData.same_as_col_name ? updateData.same_as_col_name : '',
        project_remaining_value: updateData && updateData.project_remaining_value ? updateData.project_remaining_value : false,
        dependent: updateData && updateData.dependent ? updateData.dependent : false,
        has_relation: updateData && updateData.has_relation ? updateData.has_relation : false,
        relation_col_name: updateData && updateData.relation_col_name ? updateData.relation_col_name : '',
        col_dependencies: updateData && updateData.col_dependencies ? updateData.col_dependencies : []
    }
});

watch(() => property_form_data.value.name, (updateName, oldName) => {
    // //console.log(updateName, oldName)
    if (updateName !== oldName && name_key_press.value) {
        let col_name_old = oldName.replace(/\s/g, '_').toLowerCase()
        emit('removeCertificateData', col_name_old)

        let col_name_new = updateName.replace(/\s/g, '_').toLowerCase()
        emit('modifyCertificateData', col_name_new)
    }
})

watch(() => property_form_data.value.for_certificate, (updateData, oldData) => {
    // //console.log(updateData, oldData)
    /**
     * Certificate data push
     */
    let col_name = property_form_data.value.name.replace(/\s/g, '_').toLowerCase()
    if (updateData) emit('modifyCertificateData', col_name)
    else emit('removeCertificateData', col_name)
})

watch(() => property_form_data.value.for_qr, (updateData, oldData) => {
    //console.log(updateData, oldData)
    /**
     * QR data push
     */
    let col_name = property_form_data.value.name.replace(/\s/g, '_').toLowerCase()
    if (updateData) emit('modifyQrData', col_name)
    else emit('removeQrData', col_name)
})

const alpha_update = (status) => {
    property_form_data.value.output_alpha = status
    add_properties()
}

const add_properties = () => {
    name_key_press.value = true
    emit('addProperties', property_form_data.value)
}

const add_new_dependency = (index) => {
    let obj = {
        col_name: '',
        opt: '='
    }
    property_form_data.value.col_dependencies.push(obj)
}

const remove_layer_dependency = (index, layer_index) => {
    property_form_data.value.col_dependencies.splice(index, 1)
}
</script>
<style lang="scss" scoped>
.properties_block {
    font-size: 12px;

    input,
    select {
        width: 100%;
        height: 26px;
        border: 1px solid #ddd;
        border-radius: 5px;

        &.switch_btn {
            width: 50px;
            height: 26px;
            border-radius: 25px;
            margin-left: -30px;
        }
    }
}
</style>
